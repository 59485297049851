import React from 'react';

import { callNotifyAction } from '@app/stores';
import { BackendErrorsType, ChangeFieldEventType } from '@app/types';
import { errorTranslate } from '@app/helpers';

import { useMainContext } from '../main';
import { useAppDispatch } from '@app/hooks';
import { PersonalApi } from '@app/api';


interface FormValue {
  lastName: string;
  firstName: string,
  secondName: string | null,
};

export interface DataUpdateContextInterface {
  formValue: FormValue | null;
  formDirty: boolean;
  formIsSubmitting: boolean,
  closeModal: () => void;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const useDataUpdateHook = (
): DataUpdateContextInterface => {
  const dispatch = useAppDispatch();
  const [ formValue, formValueSet ] = React.useState<FormValue | null>(null);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitted, formIsSubmittedSet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);
  const {
    userCurrent,
    modalDataUpdateOpened,
    modalDataUpdateClose,
    userCurrentSet,
  } = useMainContext();

  const closeModal = React.useCallback(() => {
    modalDataUpdateClose();
    formValueSet(null);
    formDirtySet(false);
    formIsSubmittedSet(false);
  }, [
    modalDataUpdateClose,
  ]);
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (formValue === null) return;

    formIsSubmittingSet(true);
    const response = await PersonalApi.dataUpdate(formValue);
    formIsSubmittingSet(false);
    formIsSubmittedSet(true)
    
    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Ошибка обновления данных',
      }));

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Данные успешно обновлены',
    }));

    userCurrentSet(response.data.user);
    closeModal();
  }, [
    formValue,
    closeModal,
    dispatch,
    userCurrentSet,
  ]);

  const errorSet = React.useCallback((
    fieldName: string,
  ): string | null => {
    return (formIsSubmitted && errors && errors[fieldName]) ? errorTranslate(errors[fieldName][0]) : null;
  }, [
    formIsSubmitted,
    errors,
  ]);

  React.useEffect(() => {
    if (userCurrent === null && !modalDataUpdateOpened) return;

    formValueSet(userCurrent);
  }, [
    userCurrent,
    modalDataUpdateOpened,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    closeModal,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    closeModal,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};
