import { createSlice } from '@reduxjs/toolkit';

import {
  UserStateInterface,
} from '@app/types';

import {
  getUserDictionaryAction,
} from './user.actions';


const initialState: UserStateInterface = {
  list: [],
  current: null,
  dictionary: [],
  status: 'idle',
  errors: null,
}

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserDictionaryAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getUserDictionaryAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dictionary = action.payload;
      })
      .addCase(getUserDictionaryAction.rejected, (state) => {
        state.status = 'idle';
      });
  },
});

export default userSlice.reducer;
