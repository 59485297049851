import { useNavigate } from 'react-router-dom';

import {
  signoutAction,
  setThemeMode,
} from '@app/stores';

import {
  AccountIcon,
  ButtonIconBrick,
  DividerHorizontalBrick,
  MenuDropdownBrick,
  MenuItemBrick,
  ThemeToggleBlock,
  ToolbarComposition,
  TypographyBrick,
} from '@app/components';

import {
  ThemeModeType,
} from '@app/types';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';


export const AppBarComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const themeMode = useAppSelector((s) => s.app.themeMode);

  const themeModeSet = (mode: ThemeModeType) => {
    dispatch(setThemeMode(mode));
  }

  const signout = () => {
    dispatch(signoutAction());
  }

  return (
    <ToolbarComposition
      extra={(user && (
        <>
          <h6 style={{ marginRight: '7px' }}>{user.firstName} {user.lastName}</h6>
          <MenuDropdownBrick
            button={
              <ButtonIconBrick>
                <AccountIcon />
              </ButtonIconBrick>
            }
          >
            <ThemeToggleBlock
              themeMode={themeMode}
              onLight={() => themeModeSet('light')}
              onDark={() => themeModeSet('dark')}
            />
            <DividerHorizontalBrick />
            <MenuItemBrick onClick={() => navigate('/personal')}>Настройки</MenuItemBrick>
            <MenuItemBrick onClick={signout}>Выйти</MenuItemBrick>
          </MenuDropdownBrick>
        </>
      ))}
    >
      <TypographyBrick variant="h6">AG:DCS</TypographyBrick>
    </ToolbarComposition>
  ); 
};
