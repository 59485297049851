import React from 'react';

import { callNotifyAction } from '@app/stores';

import {
  ChangeFieldEventType,
  DepartmentType,
  RoleType,
  UserType,
} from '@app/types';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { errorTranslate } from '@app/helpers';
import { useMainApi } from '..';
import { UserApi } from '@app/api';


export interface UpdateContextInterface {
  formValue: InitialFormValue | null;
  formDirty: boolean;
  formIsSubmitting: boolean;
  roleDictionary: RoleType[];
  departmentDictionary: DepartmentType[];
  changeFormValue:(e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

type InitialFormValue = Omit<UserType, 'department' | 'role'> & {
  roleId: number;
  departmentId: number;
}

export const useUpdateHook = (
): UpdateContextInterface => {
  const dispatch = useAppDispatch();
  const roleDictionary = useAppSelector((state) => state.role.dictionary);
  const departmentDictionary = useAppSelector((state) => state.department.dictionary);
  const [formIsSubmitting, formIsSubmittingSet] = React.useState(false);
  const [formValue, formValueSet] = React.useState<InitialFormValue | null>(null);
  const [formDirty, formDirtySet] = React.useState(false);

  const {
    userCurrent,
    errors,
    editorClose,
    userCurrentSet,
  } = useMainApi();

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    formIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return; 

    const response = await UserApi.update({ ...formValue });

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось обновить пользователя',
      }));
      formIsSubmittingSet(false);
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Пользователь успешно обновлен',
    }));

    userCurrentSet(response.data.user);

    formIsSubmittingSet(false);
    editorClose()
  }, [
    formValue,
    dispatch,
    editorClose,
    userCurrentSet,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && errorTranslate(errors[fieldName][0]);
  }, [
    errors,
  ]);

  React.useEffect(() => {
    if (userCurrent === null) {
      formValueSet(null);
      return;
    }

    formValueSet({
      ...userCurrent,
      roleId: userCurrent.role.id,
      departmentId: userCurrent.department.id,
    });
  }, [
    userCurrent,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    roleDictionary,
    departmentDictionary,
    changeFormValue,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    roleDictionary,
    departmentDictionary,
    changeFormValue,
    onSubmit,
    setError,
  ]);
};
