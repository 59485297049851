import { useNavigate } from 'react-router-dom';

import {
  AdminGuardIcon,
  ManageAccountsIcon,
  HandbkIcon,
  PeoplesIcon,
  FolderIcon,
  WidgetLinkBrick,
  WidgetsListBlock,
  DocumentConfigCreateFeature,
  VerifiedIcon,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import styles from './widgets-list.module.scss';


export const WidgetsListFeature = () => {
  const role = useAppSelector((state) => state.auth.user!.role);
  const navigate = useNavigate();
  const rootDocumentList = useAppSelector((state) => state.configuration.documents.filter((document) => document.parentKey === 'root'));
  const currentRole = useAppSelector((state) => state.auth.user!.role);

  return (
    <div className={styles['widgets-list']}>
      {(
        (role.DEPARTMENT_READ !== 'NEVER' || role.DEPARTMENT_CREATE !== 'NEVER') ||
        (role.ROLE_READ !== 'NEVER' || role.ROLE_CREATE !== 'NEVER') ||
        (role.USER_READ !== 'NEVER' || role.USER_CREATE !== 'NEVER') ||
        role.HANDBK_ACCESS === true
      ) && (
        <WidgetsListBlock
          title="Системные"
        >
          {(role.DEPARTMENT_READ !== 'NEVER' || role.DEPARTMENT_CREATE !== 'NEVER') && (
            <WidgetLinkBrick
              title="Подразделения"
              onClick={() => navigate('/department-list')}
              icon={<PeoplesIcon />}
            />
          )}
          {(role.ROLE_READ !== 'NEVER' || role.ROLE_CREATE !== 'NEVER') && (
            <WidgetLinkBrick
              title="Роли"
              onClick={() => navigate('/role-list')}
              icon={<AdminGuardIcon />}
            />
          )}
          {(role.USER_READ !== 'NEVER' || role.USER_CREATE !== 'NEVER') && (
            <WidgetLinkBrick
              title="Пользователи"
              onClick={() => navigate('/user-list')}
              icon={<ManageAccountsIcon />}
            />
          )}
          {(role.HANDBK_ACCESS === true) && (
            <WidgetLinkBrick
              title="Справочники"
              onClick={() => navigate('/handbk-list')}
              icon={<HandbkIcon />}
            />
          )}
          <WidgetLinkBrick
            title="Лицензия"
            onClick={() => navigate('/license')}
            icon={<VerifiedIcon />}
          />
        </WidgetsListBlock>
      )}
      <WidgetsListBlock
        title='Документы'
      >
        {currentRole.CONFIGURATOR_ACCESS && (
          <DocumentConfigCreateFeature parentKey="root" />
        )}

        {rootDocumentList.map((document) => {
          if (
            currentRole[document.key + '_CREATE'] === 'NEVER' &&
            currentRole[document.key + '_READ'] === 'NEVER' &&
            currentRole[document.key + '_UPDATE'] === 'NEVER' &&
            currentRole[document.key + '_DELETE'] === 'NEVER'
          ) return null;

          return (
            <WidgetLinkBrick
              key={document.key}
              title={document.type === 'form-list' ? document.label.plural : document.label.singular}
              onClick={() => navigate(`/${document.key + (document.type === 'form-list' ? '-list' : '-one') }`)}
              icon={<FolderIcon />}
            />
          );
        })}
      </WidgetsListBlock>
    </div>
  );
}
