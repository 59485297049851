import {
  CardContentBrick,
  CardDataComposition,
  TypographyBrick,
} from '@app/components'
import { useMainContext } from '../../context';


export const GeneralComponent = () => {
  const { userCurrent: user } = useMainContext();
  
  if (user === null) return null;

  return (
    <CardDataComposition
      title="Пользователь"
      mb12
    >
      <CardContentBrick>
        <TypographyBrick>Электронная почта: {user.email}</TypographyBrick>
        <TypographyBrick>ФИО: {user.lastName} {user.firstName} {user.secondName}</TypographyBrick>
      </CardContentBrick>
    </CardDataComposition>
  );
};