import React from 'react';

import { LicenseType } from '@app/types';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { LicenseApi } from '@app/api';
import { licenseUpdate } from '@app/stores';


export interface MainContextInterface {
  license: { active: boolean; detail: LicenseType | null; },
  licenseLoading: boolean;
  licenseRefresh: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const license = useAppSelector((s) => s.configuration.license);
  const dispatch = useAppDispatch();
  const [ licenseLoading, licenseLoadingSet ] = React.useState(false);

  const licenseRefresh = React.useCallback(async () => {
    licenseLoadingSet(true);
    const result = await LicenseApi.refresh();
    dispatch(licenseUpdate(result.data));
    licenseLoadingSet(false);
  }, [
    dispatch,
  ]);

  return React.useMemo(() => ({
    license,
    licenseLoading,
    licenseRefresh
  }), [
    license,
    licenseLoading,
    licenseRefresh,
  ]);
};
