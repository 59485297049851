import React from 'react';

import { callNotifyAction } from '@app/stores';
import { BackendErrorsType, ChangeFieldEventType } from '@app/types';
import { errorTranslate } from '@app/helpers';

import { useMainContext } from '../main';
import { PersonalApi } from '@app/api';
import { useAppDispatch } from '@app/hooks';


const initialFormValue = {
  passwordOld: '',
  passwordNew: '',
  passwordConfirm: '',
};

export interface PasswordUpdateContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean,
  errorConfirm: string | null;
  closeModal: () => void;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  errorSet: (fieldName: string) => string | null;
};

export const usePasswordUpdateHook = (
): PasswordUpdateContextInterface => {
  const dispatch = useAppDispatch();
  const { modalPasswordUpdateClose } = useMainContext();
  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitted, formIsSubmittedSet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const closeModal = React.useCallback(() => {
    modalPasswordUpdateClose();
    formValueSet(initialFormValue);
    formDirtySet(false);
    formIsSubmittedSet(false);
  }, [
    modalPasswordUpdateClose,
  ]);
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (formValue === null) return;

    formIsSubmittingSet(true);
    const response = await PersonalApi.passwordUpdate(formValue);
    formIsSubmittingSet(false);
    formIsSubmittedSet(true)

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Ошибка обновления пароля',
      }));

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Пароль успешно обновлен',
    }));

    closeModal();
  }, [
    formValue,
    closeModal,
    dispatch,
  ]);

  const errorSet = React.useCallback((
    fieldName: string,
  ): string | null => {
    return (formIsSubmitted && errors && errors[fieldName])
      ? errorTranslate(errors[fieldName][0])
      : null;
  }, [
    formIsSubmitted,
    errors,
  ]);

  const errorConfirm = React.useMemo(() => {
    return (
      formDirty
      && formValue.passwordNew !== formValue.passwordConfirm
    ) ? 'Пароли не совпадают' : null;
  }, [
    formDirty,
    formValue,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    errorConfirm,
    closeModal,
    changeFormValue,
    onSubmit,
    errorSet,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    errorConfirm,
    closeModal,
    changeFormValue,
    onSubmit,
    errorSet,
  ]);
};
