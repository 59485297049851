import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
  LicenseType,
} from '@app/types';


export class LicenseApi {
  public static async refresh(
  ): Promise<BaseResponseInterface<{ active: boolean; detail: LicenseType }>> {
    return await HttpService.sendRequest('GET', '/license/refresh');
  };
};
