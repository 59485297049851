import { useNavigate } from 'react-router-dom';

import {
  SearchDocumentIcon,
  BadgeBrick,
  ButtonBrick,
  ButtonIconBrick,
} from '@app/components';

import { ButtonSearchProps } from './button-search.props';
import styles from './button-search.module.scss';


export const ButtonSearchBlock = ({
  onClick,
  count,
  disabled,
  contained,
  type,
  buttonIcon,
}: ButtonSearchProps) => {
  const navigate = useNavigate();

  const handlerClick = () => {
    if (onClick) onClick();
    else navigate(-1);
  };

  if (buttonIcon) return (
    <div className={styles['button-search']}>
      <ButtonIconBrick
        onClick={handlerClick}
      >
        <SearchDocumentIcon />
        {count && count > 0 ? <span className={styles['button-search__badge']}>{count}</span> : null}
      </ButtonIconBrick>
    </div>
  );

  return (
    <BadgeBrick color="warning" badgeContent={count}>
      <ButtonBrick
        color="primary"
        variant={contained ? 'contained' : 'outlined'}
        startIcon={<SearchDocumentIcon />}
        onClick={onClick}
        size="large"
        disabled={disabled}
        type={type}
      >
        Поиск
      </ButtonBrick>
    </BadgeBrick>
  );
};
