import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@app/hooks';

import { LicenseGuardProps } from './license-guard.props';


export const LicenseGuardHoc = ({ children }: LicenseGuardProps) => {
  const location = useLocation();
  const loading = useAppSelector((state) => state.configuration.status === 'loading');
  const licenseActive = useAppSelector((state) => state.configuration.license.active);
  
  if (loading) return children;
  if (!licenseActive) return <Navigate to='/license-expired' state={{ from: location }} />;

  return children;
}
