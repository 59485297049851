import { SyntheticEvent, useState } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';

import { fieldConfigCreateAction } from '@app/stores';

import {
  FieldTypesType,
  ChangeFieldEventType,
} from '@app/types';

import {
  CardHeaderBrick,
  ButtonCreateBlock,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldBooleanBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FieldNumberBlock,
  FieldMultiselectBlock,
  FieldVariantsBlock,
} from '@app/components';

import {
  errorTranslate,
  translitTextHelper,
  getFieldTypesDictHelper,
} from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { FieldSelectItem } from '@app/agui/blocks/field-select/field-select.props';
import { DocumentConfigFieldCreateProps } from './document-config-field-create.props';
import styles from './document-config-field-create.module.scss';


const fieldTypes = getFieldTypesDictHelper().filter((field) => !field.deperecated);

const DocumentConfigFieldCreateComponent = ({
  documentConfig,
}: DocumentConfigFieldCreateProps) => {
  const initialFormValue = {
    label: '',
    key: '',
    type: '',
    displayList: true,
    displayOne: true,
    displayCreate: true,
    displayUpdate: true,
    required: false,
    counted: false,
    search: false,
    prompt: '',
    variants: [],
    visibleRuleEnabled: false,
    visibleRuleFieldId: null,
    visibleRuleType: null,
    visibleRuleValue: ([] as any[]),
    documentConfigLinkId: null,
    isLink: false,
    handbkId: '',
    handbkRemoteId: '',
    fileType: null,
    fileSizeKb: 200,
    unique: false,
    isPublic: false,
    accessOnlyReadRolesIds: [],
    accessReadAndWriteRolesIds: [],
  };

  const [opened, setOpened] = useState(false);
  const [formValue, setFormValue] = useState({...initialFormValue});
  const [sendedForm, setSendedForm] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const dispatch = useAppDispatch();
  const configuration = useAppSelector((state) => state.configuration);
  const roleDict = useAppSelector((state) => state.role.dictionary);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === 'label') {
      setFormValue((state) => ({
        ...state,
        key: translitTextHelper((e.target.value as string)),
      }));
    }

    if (e.target.name === 'key') {
      setFormValue((state) => ({
        ...state,
        key: (e.target.value as string).replace(/[^a-zA-Z0-9-]/g, ''),
      }));
    }

    if (e.target.name === 'type') {
      if (e.target.value === 'boolean-check') {
        setFormValue((state) => ({
          ...state,
          required: false,
          counted: false,
        }));
      }
    }

    if (e.target.name === 'visibleRuleValue' && formValue.visibleRuleType === 'equal') {
      setFormValue((state) => ({
        ...state,
        visibleRuleValue: [e.target.value],
      }));
    }
  };

  const closeForm = () => {
    setFormDirty(false);
    setFormValue({...initialFormValue});
    setSendedForm(false);
    setOpened(false)
  }

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    setSendedForm(true);

    const result = await dispatch(fieldConfigCreateAction({
      ...formValue,
      type: formValue.type as FieldTypesType,
      fileSizeKb: isNaN(Number(formValue.fileSizeKb)) ? undefined : Number(formValue.fileSizeKb),
      documentConfigId: documentConfig.documentConfigId,
    }));

    if (result.type === '@@configuration/field-config-create/fulfilled') {
      closeForm();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? configuration.errors && configuration.errors[fieldName] && errorTranslate(configuration.errors[fieldName][0])
      : '';
  }

  const getPropsConditionType = (): FieldSelectItem[] => {
    const targetFieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === formValue.visibleRuleFieldId);

    if (!targetFieldConfig) return [];

    const equalType = { value: 'equal', label: 'Равно' };
    const notNullType = { value: 'not-null', label: 'Не пустое' };
    const oneOfType = { value: 'one-of', label: 'Одно из' };
    const containsType = { value: 'contains', label: 'Содержит' };

    if (targetFieldConfig.type === 'boolean') {
      return [equalType, notNullType];
    }

    if (targetFieldConfig.type === 'gender') {
      return [equalType, notNullType];
    }

    if (
      targetFieldConfig.type === 'handbk-select' ||
      targetFieldConfig.type === 'handbk-radio' ||
      targetFieldConfig.type === 'handbk-remote-select' ||
      targetFieldConfig.type === 'handbk-remote-radio' ||
      targetFieldConfig.type === 'select'
    ) {
      return [ equalType, oneOfType, notNullType ];
    }

    if (
      targetFieldConfig.type === 'handbk-multiselect' ||
      targetFieldConfig.type === 'handbk-check' ||
      targetFieldConfig.type === 'handbk-remote-multiselect' ||
      targetFieldConfig.type === 'handbk-remote-check' ||
      targetFieldConfig.type === 'multiselect'
    ) {
      return [containsType, notNullType];
    }

    return [ notNullType ];
  }

  const buildValuesField = () => {
    const targetFieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === formValue.visibleRuleFieldId);
    if (!targetFieldConfig) return null;

    if (targetFieldConfig.type === 'boolean' && formValue.visibleRuleType === 'equal') {
      return (
        <FieldBooleanBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue[0] || null}
          onChange={changeFormValue}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (targetFieldConfig.type === 'gender' && formValue.visibleRuleType === 'equal') {
      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue[0] || null}
          onChange={changeFormValue}
          items={[{ value: 'м', label: 'Мужской' }, { value: 'ж', label: 'Женский' }]}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (targetFieldConfig.type === 'select' && formValue.visibleRuleType === 'equal') {
      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue[0]}
          onChange={changeFormValue}
          items={targetFieldConfig.variants!.map((item) => ({ value: item, label: item }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (targetFieldConfig.type === 'select' && formValue.visibleRuleType === 'one-of') {
      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || null}
          onChange={changeFormValue}
          items={targetFieldConfig.variants!.map((item) => ({ value: item, label: item }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (targetFieldConfig.type === 'multiselect' && formValue.visibleRuleType === 'contains') {
      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || []}
          onChange={changeFormValue}
          items={targetFieldConfig.variants!.map((item) => ({ value: item, label: item }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-select' || targetFieldConfig.type === 'handbk-radio')
      && formValue.visibleRuleType === 'equal'
    ) {
      const handbk = configuration.handbk.find((h) => h.handbkId === targetFieldConfig.handbkId);

      if (!handbk) return null;

      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue[0] || null}
          onChange={changeFormValue}
          items={handbk.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-select' || targetFieldConfig.type === 'handbk-radio')
      && formValue.visibleRuleType === 'one-of'
    ) {
      const handbk = configuration.handbk.find((h) => h.handbkId === targetFieldConfig.handbkId);

      if (!handbk) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue}
          onChange={changeFormValue}
          items={handbk.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-multiselect' || targetFieldConfig.type === 'handbk-check')
      && formValue.visibleRuleType === 'contains'
    ) {
      const handbk = configuration.handbk.find((h) => h.handbkId === targetFieldConfig.handbkId);

      if (!handbk) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue}
          onChange={changeFormValue}
          items={handbk.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-remote-select' || targetFieldConfig.type === 'handbk-remote-radio')
      && formValue.visibleRuleType === 'equal'
    ) {
      const handbkRemote = configuration.handbkRemote.find((h) => h.id === targetFieldConfig.handbkRemoteId);

      if (!handbkRemote) return null;

      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue[0]}
          onChange={changeFormValue}
          items={handbkRemote.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-remote-select' || targetFieldConfig.type === 'handbk-remote-radio')
      && formValue.visibleRuleType === 'one-of'
    ) {
      const handbkRemote = configuration.handbkRemote.find((h) => h.id === targetFieldConfig.handbkRemoteId);

      if (!handbkRemote) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || []}
          onChange={changeFormValue}
          items={handbkRemote.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-remote-multiselect' || targetFieldConfig.type === 'handbk-remote-check')
      && formValue.visibleRuleType === 'contains'
    ) {
      const handbkRemote = configuration.handbkRemote.find((h) => h.id === targetFieldConfig.handbkRemoteId);

      if (!handbkRemote) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || []}
          onChange={changeFormValue}
          items={handbkRemote.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }
  }

  return (
    <div className={styles['document-config-field-create']}>
      <ButtonCreateBlock buttonIcon onClick={() => setOpened(true)} />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={opened}
        onClose={() => setOpened(false)}
      >
        <CardHeaderBrick
          title="Добавить поле"
          extra={<ButtonCancelBlock buttonIcon onClick={() => closeForm()} />}
        />
        <DialogContent>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-6">
              <form
                className={styles['document-config-field-create__form']}
                onSubmit={(e: SyntheticEvent) => onSubmit(e)}
              >
                <Typography variant="h6">Основное</Typography>
                <FieldSelectBlock
                  name="type"
                  label="Тип"
                  value={formValue.type}
                  onChange={changeFormValue}
                  error={setError('type')}
                  items={fieldTypes}
                  dropdownMaxHeight={400}
                  enableSearch
                />

                {(
                  formValue.type === 'handbk-select' ||
                  formValue.type === 'handbk-multiselect' ||
                  formValue.type === 'handbk-radio' ||
                  formValue.type === 'handbk-check'
                ) && (
                  <FieldSelectBlock
                    name="handbkId"
                    label="Справочник"
                    value={formValue.handbkId}
                    onChange={changeFormValue}
                    error={setError('handbkId')}
                    items={configuration.handbk.map((h) => ({
                      value: h.handbkId,
                      label: h.name,
                    }))}
                  />
                )}

                {(
                  formValue.type === 'handbk-remote-select' ||
                  formValue.type === 'handbk-remote-multiselect' ||
                  formValue.type === 'handbk-remote-radio' ||
                  formValue.type === 'handbk-remote-check'
                ) && (
                  <FieldSelectBlock
                    name="handbkRemoteId"
                    label="Удаленный справочник"
                    value={formValue.handbkRemoteId}
                    onChange={changeFormValue}
                    error={setError('handbkRemoteId')}
                    items={configuration.handbkRemote.map((h) => ({
                      value: h.id,
                      label: h.name,
                    }))}
                  />
                )}

                {(formValue.type === 'file' && (
                  <>
                    <FieldSelectBlock
                      name="fileType"
                      label="Тип файла"
                      value={formValue.fileType}
                      onChange={changeFormValue}
                      error={setError('fileType')}
                      items={[
                        { value: 'image', label: 'Изображение (*.jpg, *.jpeg, *.png, *.webp)' },
                        { value: 'word', label: 'MS Word (*.doc, *.docx, *.rtf)' },
                        { value: 'excel', label: 'MS Excel (*.xls, *.xlsx, *.csv)' },
                        { value: 'archive', label: 'Архив (*.rar, *.zip)' },
                        { value: 'txt', label: 'Текстовый файл (*.txt)' },
                        { value: 'pdf', label: 'PDF (*.pdf)' },
                        { value: 'any', label: 'Любой файл' },
                      ]}
                    />

                    <FieldNumberBlock
                      name="fileSizeKb"
                      label="Размер файла (Кб)"
                      value={formValue.fileSizeKb || NaN}
                      onChange={changeFormValue}
                      error={setError('fileSizeKb')}
                    />
                  </>
                ))}

                {(formValue.type === 'document-select' || formValue.type === 'document-multiselect') && (
                  <FieldSelectBlock
                    name="documentConfigLinkId"
                    label="Коллекция"
                    value={formValue.documentConfigLinkId}
                    onChange={changeFormValue}
                    error={setError('documentConfigLinkId')}
                    items={configuration.documents.map((document) => ({
                      label: document.label.singular,
                      value: document.documentConfigId,
                    })).filter((document) => document.value !== documentConfig.documentConfigId)}
                  />
                )}

                <FieldTextBlock
                  name="label"
                  label="Название"
                  value={formValue.label}
                  onChange={changeFormValue}
                  error={setError('label')}
                />
                <FieldTextBlock
                  name="key"
                  label="Ключ"
                  value={formValue.key}
                  onChange={changeFormValue}
                  error={setError('key')}
                />
                {(formValue.type === 'multiselect' || formValue.type === 'select') && <FieldVariantsBlock
                  name="variants"
                  label="Варианты ответа"
                  value={formValue.variants}
                  onChange={changeFormValue}
                  error={setError('variants')}
                />}
                <FieldTextBlock
                  name="prompt"
                  label="Подсказка"
                  value={formValue.prompt}
                  onChange={changeFormValue}
                  error={setError('prompt')}
                />
                <FieldBooleanBlock
                  name="required"
                  label="Обязательное"
                  value={formValue.required}
                  onChange={changeFormValue}
                  error={setError('required')}
                  disabled={formValue.type === 'boolean-check'}
                />
                {formValue.required === false && <FieldBooleanBlock
                  name="counted"
                  label="Учавствует в расчете прогресса"
                  value={formValue.counted}
                  onChange={changeFormValue}
                  error={setError('counted')}
                  disabled={formValue.type === 'boolean-check'}
                />}

                <FieldBooleanBlock
                  name="unique"
                  label="Уникальное значение"
                  value={formValue.unique}
                  onChange={changeFormValue}
                  error={setError('unique')}
                />

                {formValue.type === 'text' && (
                  <FieldBooleanBlock
                    name="isPublic"
                    label="Может быть использовано для публичной идентификации"
                    value={formValue.isPublic}
                    onChange={changeFormValue}
                    error={setError('isPublic')}
                  />
                )}

                {(
                  formValue.type === 'select' ||
                  formValue.type === 'text' ||
                  formValue.type === 'long-text' ||
                  formValue.type === 'snils' ||
                  formValue.type === 'passport' ||
                  formValue.type === 'gender' ||
                  formValue.type === 'number-string' ||
                  formValue.type === 'number' ||
                  formValue.type === 'decimal-string' ||
                  formValue.type === 'decimal' ||
                  formValue.type === 'big-int' ||
                  formValue.type === 'phone' ||
                  formValue.type === 'month' ||
                  formValue.type === 'year' ||
                  formValue.type === 'boolean' ||
                  formValue.type === 'boolean-check'
                ) && <FieldBooleanBlock
                  name="search"
                  label="Участвует в поиске"
                  value={formValue.search}
                  onChange={changeFormValue}
                  error={setError('search')}
                />}

                <Typography variant="h6">Видимость поля</Typography>
                <FieldBooleanBlock
                  name="isLink"
                  label="Отображение в списке как ссылка"
                  value={formValue.isLink}
                  onChange={changeFormValue}
                  error={setError('isLink')}
                />
                <FieldBooleanBlock
                  name="displayList"
                  label="Отображение в списке"
                  value={formValue.displayList}
                  onChange={changeFormValue}
                  error={setError('displayList')}
                />
                <FieldBooleanBlock
                  name="displayOne"
                  label="Отображение на детальной странице"
                  value={formValue.displayOne}
                  onChange={changeFormValue}
                  error={setError('displayOne')}
                />
                <FieldBooleanBlock
                  name="displayCreate"
                  label="Отображание в форме создания"
                  value={formValue.displayCreate}
                  onChange={changeFormValue}
                  error={setError('displayCreate')}
                />
                <FieldBooleanBlock
                  name="displayUpdate"
                  label="Отображание в форме обновления"
                  value={formValue.displayUpdate}
                  onChange={changeFormValue}
                  error={setError('displayUpdate')}
                />

                <Typography variant="h6">Правило отображения</Typography>
                <FieldBooleanBlock
                  name="visibleRuleEnabled"
                  label="Задать правило отображения"
                  value={formValue.visibleRuleEnabled}
                  onChange={changeFormValue}
                />

                {formValue.visibleRuleEnabled && <FieldSelectBlock
                  name="visibleRuleFieldId"
                  label="Если поле"
                  value={formValue.visibleRuleFieldId}
                  onChange={changeFormValue}
                  items={documentConfig!.fields.map((fieldConfig) => ({
                    value: fieldConfig.fieldConfigId,
                    label: fieldConfig.label,
                  }))}
                  error={setError('visibleRuleFieldId')}
                />}

                {(formValue.visibleRuleEnabled && formValue.visibleRuleFieldId) && <FieldSelectBlock
                  name="visibleRuleType"
                  label="Условие"
                  value={formValue.visibleRuleType}
                  onChange={changeFormValue}
                  items={getPropsConditionType()}
                  error={setError('visibleRuleType')}
                />}

                {buildValuesField()}
                
                <Typography variant="h6">Правила доступа</Typography>
                <FieldMultiselectBlock
                  name="accessOnlyReadRolesIds"
                  label="Разрешить показывать поле только"
                  value={formValue.accessOnlyReadRolesIds}
                  onChange={changeFormValue}
                  items={roleDict.map((role) => ({ value: role.id, label: role.name }))}
                  error={setError('accessOnlyReadRolesIds')}
                />
                <FieldMultiselectBlock
                  name="accessReadAndWriteRolesIds"
                  label="Разрешить редактировать поле только"
                  value={formValue.accessReadAndWriteRolesIds}
                  onChange={changeFormValue}
                  items={roleDict.map((role) => ({ value: role.id, label: role.name }))}
                  error={setError('accessReadAndWriteRolesIds')}
                />

                <ButtonSaveBlock
                  loading={configuration.status === 'submitting'}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={() => closeForm()} />
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export { DocumentConfigFieldCreateComponent };
