import React from 'react';
import { useParams } from 'react-router-dom';

import { callNotifyAction } from '@app/stores';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { BackendErrorsType, UserType } from '@app/types';
import { UserApi } from '@app/api';


export interface MainContextInterface {
  id: string | undefined;
  userDetailIsLoading: boolean;
  hasUpdateAccess: boolean;
  isEditing: boolean;
  modalChangePasswordOpened: boolean;
  userCurrent: UserType | null;
  errors: BackendErrorsType | null;
  editorClose: () => void;
  editorOpen: () => void;
  modalChangePasswordOpen: () => void;
  modalChangePasswordClose: () => void;
  userCurrentSet: React.Dispatch<React.SetStateAction<UserType | null>>;
};

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [userDetailIsLoading, userDetailIsLoadingSet] = React.useState(false);
  const [userCurrent, userCurrentSet] = React.useState<UserType | null>(null);
  const [isEditing, isEditingSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);
  const [modalChangePasswordOpened, modalChangePasswordOpenedSet] = React.useState(false);

  const hasUpdateAccess = useAppSelector((state) => state.auth.user?.role.USER_UPDATE !== 'NEVER');

  const getUserOne = React.useCallback(async () => {
    userDetailIsLoadingSet(true);
    const response = await UserApi.getOne((Number(id)));

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось загрузить пользователя',
      }));

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    userCurrentSet(response.data.user);
    userDetailIsLoadingSet(false);
  }, [
    dispatch,
    id,
  ]);

  const editorClose = React.useCallback(() => {
    isEditingSet(false);
  }, [
    isEditingSet,
  ]);

  const editorOpen = React.useCallback(() => {
    isEditingSet(true);
  }, [
    isEditingSet,
  ]);

  const modalChangePasswordOpen = React.useCallback(() => {
    modalChangePasswordOpenedSet(true);
  }, [
    modalChangePasswordOpenedSet,
  ]);

  const modalChangePasswordClose = React.useCallback(() => {
    modalChangePasswordOpenedSet(false);
  }, [
    modalChangePasswordOpenedSet,
  ]);

  React.useEffect(() => {
    getUserOne()
  }, [
    getUserOne,
  ]);

  return React.useMemo(() => ({
    id,
    userDetailIsLoading,
    hasUpdateAccess,
    isEditing,
    modalChangePasswordOpened,
    userCurrent,
    errors,
    editorClose,
    editorOpen,
    modalChangePasswordOpen,
    modalChangePasswordClose,
    userCurrentSet,
  }), [
    id,
    userDetailIsLoading,
    hasUpdateAccess,
    isEditing,
    modalChangePasswordOpened,
    userCurrent,
    errors,
    editorClose,
    editorOpen,
    modalChangePasswordOpen,
    modalChangePasswordClose,
    userCurrentSet,
  ]);
};
