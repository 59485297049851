import { Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';

import {
  // LinkBrick,
  PageCenteringLayout,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

// import styles from './not-found.module.scss';


export const LicenseExpiredPage = () => {
  const licenseActive = useAppSelector((state) => state.configuration.license.active);
  if (licenseActive) return <Navigate to='/' />;

  return (
    <PageCenteringLayout>
      <Typography variant="h1" style={{ color: 'red', letterSpacing: '17px' }}>ПРОСРОЧЕНО</Typography>
    </PageCenteringLayout>
  );
};
