import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import {
  configurationBuildAction,
  getRoleDictionaryAction,
  getDepartmentDictionaryAction,
} from '@app/stores';

import {
  RoleListPage,
  RoleCreatePage,
  RoleDetailPage,
  UserListPage,
  UserCreatePage,
  UserDetailPage,
  HandbkListPage,
  HomePage,
  SigninPage,
  NotFoundPage,
  DepartmentCreatePage,
  DepartmentDetailPage,
  DepartmentListPage,
  DocumentPage,
  DocumentSearchPage,
  AnonGuardHoc,
  AuthGuardHoc,
  PersonalPage,
  LicenseExpiredPage,
  LicenseGuardHoc,
  LicensePage,
} from '@app/components';

import { buildPaths } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';


export const RouterOutletPart = () => {
  const documentConfigs = useAppSelector((state) => state.configuration.documents);
  const dispatch = useAppDispatch();
  const userAuth = useAppSelector((state) => state.auth.user);

  const router = createBrowserRouter([
    { path: '/', element: <AuthGuardHoc><HomePage /></AuthGuardHoc> },
    { path: '/department-list', element: <AuthGuardHoc><LicenseGuardHoc><DepartmentListPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/department-list/department-create', element: <AuthGuardHoc><LicenseGuardHoc><DepartmentCreatePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/department-list/:id', element: <AuthGuardHoc><LicenseGuardHoc><DepartmentDetailPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/personal', element: <AuthGuardHoc><LicenseGuardHoc><PersonalPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/role-list', element: <AuthGuardHoc><LicenseGuardHoc><RoleListPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/role-list/role-create', element: <AuthGuardHoc><LicenseGuardHoc><RoleCreatePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/role-list/:id', element: <AuthGuardHoc><LicenseGuardHoc><RoleDetailPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/user-list', element: <AuthGuardHoc><LicenseGuardHoc><UserListPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/user-list/user-create', element: <AuthGuardHoc><LicenseGuardHoc><UserCreatePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/user-list/:id', element: <AuthGuardHoc><LicenseGuardHoc><UserDetailPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/document-search/:uuid', element: <AuthGuardHoc><LicenseGuardHoc><DocumentSearchPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/handbk-list', element: <AuthGuardHoc><LicenseGuardHoc><HandbkListPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },
    { path: '/license', element: <AuthGuardHoc><LicensePage /></AuthGuardHoc> },
    { path: '/license-expired', element: <LicenseExpiredPage /> },

    ...buildPaths(documentConfigs).map((path) => {
      return {
        path,
        element: <AuthGuardHoc><LicenseGuardHoc><DocumentPage /></LicenseGuardHoc></AuthGuardHoc>
      }
    }),

    { path: '*', element: <NotFoundPage /> },
  ]);

  React.useEffect(() => {
    dispatch(configurationBuildAction());
    dispatch(getRoleDictionaryAction());
    dispatch(getDepartmentDictionaryAction());
  }, [
    dispatch,
    userAuth,
  ])
  

  return (
    <RouterProvider router={router} />
  );
};
