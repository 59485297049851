import { useState } from 'react';
import cs from 'classnames';

import {
  DeleteDocumentIcon,
  DialogBrick,
  DialogTitleBrick,
  DialogContentBrick,
  DialogContentTextBrick,
  DialogActionsBrick,
  ButtonBrick,
  ButtonIconBrick,
} from '@app/components';

import { ButtonConfirmProps } from './button-confirm.props';
import styles from './button-confirm.module.scss';


export const ButtonConfirmComposition = ({
  onClick,
  message,
  size = 'large',
  type = 'icon',
  variant = 'outlined',
  buttonText = 'Удалить',
  fullWidth = false,
  icon,
}: ButtonConfirmProps) => {
  const [opened, setOpened] = useState(false);

  const agreeHandler = () => {
    setOpened(false);
    onClick();
  };

  const classnames = cs(
    styles['button-confirm'],
    { [styles['button-confirm--fullwidth']]: fullWidth },
  );

  return (
    <div className={classnames}>
      {type === 'icon' ? (
        <ButtonIconBrick onClick={() => setOpened(true)} aria-label="delete" size={size}>
          {icon || <DeleteDocumentIcon />}
        </ButtonIconBrick>
      ) : (
        <ButtonBrick
          onClick={() => setOpened(true)}
          size={size}
          variant={variant}
          startIcon={icon}
          fullWidth={fullWidth}
        >
          {buttonText}
        </ButtonBrick>
      )}

      <DialogBrick
        opened={opened}
        onClose={() => setOpened(false)}
        size="small"
      >
        <DialogTitleBrick id="alert-dialog-title">Вы уверены?</DialogTitleBrick>
        <DialogContentBrick className={styles['button-confirm__content']}>
          <DialogContentTextBrick id="alert-dialog-description">
            { message ? message : 'Текущая запись, а также все связанные с ней записи будут удалены.' }
          </DialogContentTextBrick>
        </DialogContentBrick>
        <DialogActionsBrick>
          <ButtonBrick onClick={() => setOpened(false)} autoFocus>Отмена</ButtonBrick>
          <ButtonBrick
            onClick={agreeHandler}
          >
            Подтверждаю
          </ButtonBrick>
        </DialogActionsBrick>
      </DialogBrick>
    </div>
  );
};
