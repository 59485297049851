import { useEffect } from 'react';

import {
  setBreadcrumbs,
  getDepartmentDictionaryAction,
} from '@app/stores';

import {
  PageBaseLayout,
  UserCreateFeature,
} from '@app/components';

import { useAppDispatch, useAppSelector } from '@app/hooks';


export const UserCreatePage = () => {
  const dispatch = useAppDispatch();
  const { breadcrumbs } = useAppSelector((s) => s.app);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Добавить пользователя', link: '' },
    ]))

    dispatch(getDepartmentDictionaryAction());
  }, [dispatch])

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <UserCreateFeature />
    </PageBaseLayout>
  );
};
