import { SyntheticEvent, useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';

import {
  fieldConfigDeleteAction,
  fieldConfigUpdateAction,
} from '@app/stores';

import {
  FieldVisibleRuleTypesType,
  ChangeFieldEventType,
} from '@app/types';

import {
  CardHeaderBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldBooleanBlock,
  FieldSelectBlock,
  FieldMultiselectBlock,
  FieldTextBlock,
  FieldNumberBlock,
  FieldVariantsBlock,
  ButtonConfirmComposition,
} from '@app/components';

import { errorTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { FieldSelectItem } from '@app/agui/blocks/field-select/field-select.props';
import { DocumentConfigFieldUpdateProps } from './document-config-field-update.props';
import styles from './document-config-field-update.module.scss';


const DocumentConfigFieldUpdateComponent = ({
  fieldConfig,
  documentConfig,
  closeDialog,
}: DocumentConfigFieldUpdateProps) => {
  const initialFormValue = {
    label: '',
    displayList: true,
    displayOne: true,
    displayCreate: true,
    displayUpdate: true,
    required: false,
    counted: false,
    search: false,
    prompt: '',
    variants: ([] as string[]),
    visibleRuleEnabled: false,
    visibleRuleFieldId: (null as number | null),
    visibleRuleType: (null as FieldVisibleRuleTypesType | null),
    visibleRuleValue: (undefined as any[] | undefined),
    isLink: false,
    fileType: (null as string | null),
    fileSizeKb: (200 as number | undefined),
    unique: false,
    isPublic: false,
    accessOnlyReadRolesIds: [] as number[],
    accessReadAndWriteRolesIds: [] as number[],
  }

  const [formValue, setFormValue] = useState({...initialFormValue});
  const [sendedForm, setSendedForm] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const dispatch = useAppDispatch();
  const configuration = useAppSelector((state) => state.configuration);
  const roleDict = useAppSelector((state) => state.role.dictionary);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === 'visibleRuleValue' && formValue.visibleRuleType === 'equal') {
      setFormValue((state) => ({
        ...state,
        visibleRuleValue: [e.target.value],
      }));
    }
  };

  const closeUpdateDialog = () => {
    setFormDirty(false);
    setSendedForm(false);
    setFormValue({...initialFormValue});
    closeDialog();
  }
  
  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    setSendedForm(true);

    const result = await dispatch(fieldConfigUpdateAction({
      ...formValue,
      fieldConfigId: fieldConfig!.fieldConfigId,
    }));

    if (result.type === '@@configuration/field-config-update/fulfilled') {
      closeUpdateDialog();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? configuration.errors && configuration.errors[fieldName] && errorTranslate(configuration.errors[fieldName][0])
      : '';
  }

  const onRemoveFieldConfig = async () => {
    const result = await dispatch(fieldConfigDeleteAction({
      fieldConfigId: fieldConfig!.fieldConfigId,
    }));

    if (result.type === '@@configuration/field-config-delete/fulfilled') {
      closeUpdateDialog();
    }
  }

  useEffect(() => {
    const resetForm = () => {
      setFormValue({...initialFormValue});
      setFormDirty(false);
    }

    if (fieldConfig === null) {
      resetForm();
    } else {
      setFormValue({
        label: fieldConfig.label,
        displayList: fieldConfig.display.list,
        displayOne: fieldConfig.display.one,
        displayCreate: fieldConfig.display.create,
        displayUpdate: fieldConfig.display.update,
        required: fieldConfig.required,
        counted: fieldConfig.counted,
        search: fieldConfig.search,
        prompt: fieldConfig.prompt || '',
        variants: fieldConfig.variants || [],
        visibleRuleEnabled: !!fieldConfig.visibleRule,
        visibleRuleFieldId: fieldConfig.visibleRule ? fieldConfig.visibleRule.fieldId : null,
        visibleRuleType: fieldConfig.visibleRule ? fieldConfig.visibleRule.type : null,
        visibleRuleValue: fieldConfig.visibleRule ? fieldConfig.visibleRule.value : undefined,
        isLink: fieldConfig.display.isLink,
        fileType: fieldConfig.fileType,
        fileSizeKb: fieldConfig.fileSizeKb,
        unique: fieldConfig.unique ? fieldConfig.unique : false,
        isPublic: fieldConfig.isPublic,
        accessOnlyReadRolesIds: fieldConfig.accessOnlyReadRolesIds,
        accessReadAndWriteRolesIds: fieldConfig.accessReadAndWriteRolesIds,
      })
    }
  }, [fieldConfig]) // eslint-disable-line

  const getPropsConditionType = (): FieldSelectItem[] => {
    const targetFieldConfig = documentConfig!.fields.find((fieldConfig) => fieldConfig.fieldConfigId === formValue.visibleRuleFieldId);

    if (!targetFieldConfig) return [];

    const equalType = { value: 'equal', label: 'Равно' };
    const notNullType = { value: 'not-null', label: 'Не пустое' };
    const oneOfType = { value: 'one-of', label: 'Одно из' };
    const containsType = { value: 'contains', label: 'Содержит' };

    if (targetFieldConfig.type === 'boolean') {
      return [equalType, notNullType];
    }

    if (targetFieldConfig.type === 'gender') {
      return [equalType, notNullType];
    }

    if (
      targetFieldConfig.type === 'handbk-select' ||
      targetFieldConfig.type === 'handbk-radio' ||
      targetFieldConfig.type === 'handbk-remote-select' ||
      targetFieldConfig.type === 'handbk-remote-radio' ||
      targetFieldConfig.type === 'select'
    ) {
      return [ equalType, oneOfType, notNullType ];
    }

    if (
      targetFieldConfig.type === 'handbk-multiselect' ||
      targetFieldConfig.type === 'handbk-check' ||
      targetFieldConfig.type === 'handbk-remote-multiselect' ||
      targetFieldConfig.type === 'handbk-remote-check' ||
      targetFieldConfig.type === 'multiselect'
    ) {
      return [containsType, notNullType];
    }

    return [ notNullType ];
  }

  const buildValuesField = () => {
    const targetFieldConfig = documentConfig!.fields.find((fieldConfig) => fieldConfig.fieldConfigId === formValue.visibleRuleFieldId);
    if (!targetFieldConfig) return null;

    if (targetFieldConfig.type === 'boolean' && formValue.visibleRuleType === 'equal') {
      return (
        <FieldBooleanBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue ? formValue.visibleRuleValue[0] : null}
          onChange={changeFormValue}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (targetFieldConfig.type === 'gender' && formValue.visibleRuleType === 'equal') {
      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue ? formValue.visibleRuleValue[0] : null}
          onChange={changeFormValue}
          items={[{ value: 'м', label: 'Мужской' }, { value: 'ж', label: 'Женский' }]}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (targetFieldConfig.type === 'select' && formValue.visibleRuleType === 'equal') {
      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue ? formValue.visibleRuleValue[0] : null}
          onChange={changeFormValue}
          items={targetFieldConfig.variants!.map((item) => ({ value: item, label: item }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (targetFieldConfig.type === 'select' && formValue.visibleRuleType === 'one-of') {
      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || null}
          onChange={changeFormValue}
          items={targetFieldConfig.variants!.map((item) => ({ value: item, label: item }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-select' || targetFieldConfig.type === 'handbk-radio')
      && formValue.visibleRuleType === 'equal'
    ) {
      const handbk = configuration.handbk.find((h) => h.handbkId === targetFieldConfig.handbkId);

      if (!handbk) return null;

      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue ? formValue.visibleRuleValue[0] : null}
          onChange={changeFormValue}
          items={handbk.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-select' || targetFieldConfig.type === 'handbk-radio')
      && formValue.visibleRuleType === 'one-of'
    ) {
      const handbk = configuration.handbk.find((h) => h.handbkId === targetFieldConfig.handbkId);

      if (!handbk) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || []}
          onChange={changeFormValue}
          items={handbk.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-multiselect' || targetFieldConfig.type === 'handbk-check')
      && formValue.visibleRuleType === 'contains'
    ) {
      const handbk = configuration.handbk.find((h) => h.handbkId === targetFieldConfig.handbkId);

      if (!handbk) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || []}
          onChange={changeFormValue}
          items={handbk.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-remote-select' || targetFieldConfig.type === 'handbk-remote-radio')
      && formValue.visibleRuleType === 'equal'
    ) {
      const handbkRemote = configuration.handbkRemote.find((h) => h.id === targetFieldConfig.handbkRemoteId);

      if (!handbkRemote) return null;

      return (
        <FieldSelectBlock
          name="visibleRuleValue"
          label="Значение"
          value={formValue.visibleRuleValue ? formValue.visibleRuleValue[0] : null}
          onChange={changeFormValue}
          items={handbkRemote.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-remote-select' || targetFieldConfig.type === 'handbk-remote-radio')
      && formValue.visibleRuleType === 'one-of'
    ) {
      const handbkRemote = configuration.handbkRemote.find((h) => h.id === targetFieldConfig.handbkRemoteId);

      if (!handbkRemote) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || []}
          onChange={changeFormValue}
          items={handbkRemote.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }

    if (
      (targetFieldConfig.type === 'handbk-remote-multiselect' || targetFieldConfig.type === 'handbk-remote-check')
      && formValue.visibleRuleType === 'contains'
    ) {
      const handbkRemote = configuration.handbkRemote.find((h) => h.id === targetFieldConfig.handbkRemoteId);

      if (!handbkRemote) return null;

      return (
        <FieldMultiselectBlock
          name="visibleRuleValue"
          label="Значение"
          value={(formValue.visibleRuleValue as string[]) || []}
          onChange={changeFormValue}
          items={handbkRemote.variants.map((item) => ({ value: item.id, label: item.label }))}
          error={setError('visibleRuleValue')}
        />
      );
    }
  }


  return (
    <div className={styles['document-config-field-update']}>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={!!fieldConfig}
        onClose={() => closeUpdateDialog()}
      >
        <CardHeaderBrick
          title="Редактировать поле"
          extra={
            <>
              <ButtonConfirmComposition
                message="Текущая колонка и связанные с ней данные будут удалены."
                onClick={() => onRemoveFieldConfig()}
              />
              <ButtonCancelBlock buttonIcon onClick={() => closeUpdateDialog()} />
            </>
          }
        />
        <DialogContent>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-6">
              <form
                className={styles['document-config-field-update__form']}
                onSubmit={(e: SyntheticEvent) => onSubmit(e)}
              >
                <Typography variant="h6">Основное</Typography>
                <FieldTextBlock
                  name="label"
                  label="Название"
                  value={formValue.label}
                  onChange={changeFormValue}
                  error={setError('label')}
                />

                {(fieldConfig && fieldConfig.type === 'file' && (
                  <>
                    <FieldSelectBlock
                      name="fileType"
                      label="Тип файла"
                      value={formValue.fileType}
                      onChange={changeFormValue}
                      error={setError('fileType')}
                      items={[
                        { value: 'image', label: 'Изображение (*.jpg, *.jpeg, *.png, *.webp)' },
                        { value: 'word', label: 'MS Word (*.doc, *.docx, *.rtf)' },
                        { value: 'excel', label: 'MS Excel (*.xls, *.xlsx, *.csv)' },
                        { value: 'archive', label: 'Архив (*.rar, *.zip)' },
                        { value: 'txt', label: 'Текстовый файл (*.txt)' },
                        { value: 'pdf', label: 'PDF (*.pdf)' },
                        { value: 'any', label: 'Любой файл' },
                      ]}
                    />

                    <FieldNumberBlock
                      name="fileSizeKb"
                      label="Размер файла (Кб)"
                      value={formValue.fileSizeKb || NaN}
                      onChange={changeFormValue}
                      error={setError('fileSizeKb')}
                    />
                  </>
                ))}
                {(fieldConfig && (fieldConfig.type === 'multiselect' || fieldConfig.type === 'select')) && <FieldVariantsBlock
                  name="variants"
                  label="Варианты ответа"
                  value={formValue.variants}
                  onChange={changeFormValue}
                  error={setError('variants')}
                />}
                <FieldTextBlock
                  name="prompt"
                  label="Подсказка"
                  value={formValue.prompt}
                  onChange={changeFormValue}
                  error={setError('prompt')}
                />
                <FieldBooleanBlock
                  name="required"
                  label="Обязательное"
                  value={formValue.required}
                  onChange={changeFormValue}
                  error={setError('required')}
                  disabled={(fieldConfig && fieldConfig.type === 'boolean-check') || false}
                />
                {formValue.required === false && (
                  <FieldBooleanBlock
                    name="counted"
                    label="Учавствует в расчете прогресса"
                    value={formValue.counted}
                    onChange={changeFormValue}
                    error={setError('counted')}
                    disabled={(fieldConfig && fieldConfig.type === 'boolean-check') || false}
                  />
                )}
                {(fieldConfig && (
                  fieldConfig.type === 'select' ||
                  fieldConfig.type === 'text' ||
                  fieldConfig.type === 'long-text' ||
                  fieldConfig.type === 'snils' ||
                  fieldConfig.type === 'passport' ||
                  fieldConfig.type === 'number-string' ||
                  fieldConfig.type === 'number' ||
                  fieldConfig.type === 'decimal-string' ||
                  fieldConfig.type === 'decimal' ||
                  fieldConfig.type === 'big-int' ||
                  fieldConfig.type === 'gender' ||
                  fieldConfig.type === 'phone' ||
                  fieldConfig.type === 'month' ||
                  fieldConfig.type === 'year' ||
                  fieldConfig.type === 'boolean' ||
                  fieldConfig.type === 'boolean-check'
                )) && <FieldBooleanBlock
                  name="search"
                  label="Участвует в поиске"
                  value={formValue.search}
                  onChange={changeFormValue}
                  error={setError('search')}
                />}

                <FieldBooleanBlock
                  name="unique"
                  label="Уникальное значение"
                  value={formValue.unique}
                  onChange={changeFormValue}
                  error={setError('unique')}
                />

                {(fieldConfig && fieldConfig.type === 'text') && (
                  <FieldBooleanBlock
                    name="isPublic"
                    label="Может быть использовано для публичной идентификации"
                    value={formValue.isPublic}
                    onChange={changeFormValue}
                    error={setError('isPublic')}
                  />
                )}

                <Typography variant="h6">Видимость поля</Typography>
                <FieldBooleanBlock
                  name="isLink"
                  label="Отображение в списке как ссылка"
                  value={formValue.isLink}
                  onChange={changeFormValue}
                  error={setError('isLink')}
                />
                <FieldBooleanBlock
                  name="displayList"
                  label="Отображение в списке"
                  value={formValue.displayList}
                  onChange={changeFormValue}
                  error={setError('displayList')}
                />
                <FieldBooleanBlock
                  name="displayOne"
                  label="Отображение на детальной странице"
                  value={formValue.displayOne}
                  onChange={changeFormValue}
                  error={setError('displayOne')}
                />
                <FieldBooleanBlock
                  name="displayCreate"
                  label="Отображание в форме создания"
                  value={formValue.displayCreate}
                  onChange={changeFormValue}
                  error={setError('displayCreate')}
                />
                <FieldBooleanBlock
                  name="displayUpdate"
                  label="Отображание в форме обновления"
                  value={formValue.displayUpdate}
                  onChange={changeFormValue}
                  error={setError('displayUpdate')}
                />

                <Typography variant="h6">Правило отображения</Typography>
                <FieldBooleanBlock
                  name="visibleRuleEnabled"
                  label="Задать правило отображения"
                  value={formValue.visibleRuleEnabled}
                  onChange={changeFormValue}
                />

                {formValue.visibleRuleEnabled && <FieldSelectBlock
                  name="visibleRuleFieldId"
                  label="Если поле"
                  value={String(formValue.visibleRuleFieldId)}
                  onChange={changeFormValue}
                  items={documentConfig!.fields.filter((config) => config.fieldConfigId !== fieldConfig!.fieldConfigId).map((fieldConfig) => ({
                    value: fieldConfig.fieldConfigId,
                    label: fieldConfig.label,
                  }))}
                  error={setError('visibleRuleFieldId')}
                />}

                {(formValue.visibleRuleEnabled && formValue.visibleRuleFieldId) && <FieldSelectBlock
                  name="visibleRuleType"
                  label="Условие"
                  value={formValue.visibleRuleType}
                  onChange={changeFormValue}
                  items={getPropsConditionType()}
                  error={setError('visibleRuleType')}
                />}

                {buildValuesField()}
                
                <Typography variant="h6">Правила доступа</Typography>
                <FieldMultiselectBlock
                  name="accessOnlyReadRolesIds"
                  label="Разрешить показывать поле только"
                  value={formValue.accessOnlyReadRolesIds}
                  onChange={changeFormValue}
                  items={roleDict.map((role) => ({ value: role.id, label: role.name }))}
                  error={setError('accessOnlyReadRolesIds')}
                />
                <FieldMultiselectBlock
                  name="accessReadAndWriteRolesIds"
                  label="Разрешить редактировать поле только"
                  value={formValue.accessReadAndWriteRolesIds}
                  onChange={changeFormValue}
                  items={roleDict.map((role) => ({ value: role.id, label: role.name }))}
                  error={setError('accessReadAndWriteRolesIds')}
                />

                <ButtonSaveBlock
                  loading={configuration.status === 'submitting'}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={() => closeUpdateDialog()} />
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export { DocumentConfigFieldUpdateComponent };
