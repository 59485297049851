import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { setBreadcrumbs } from '@app/stores';

import {
  PageBaseLayout,
  UserDetailFeature,
} from '@app/components';

import { useAppDispatch, useAppSelector } from '@app/hooks';

import { UserDetailPageParams } from './user-detail.page-params';


export const UserDetailPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<UserDetailPageParams>();
  const { breadcrumbs } = useAppSelector((s) => s.app);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Пользователь №' + id, link: '' },
    ]));
  }, [dispatch, id]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <UserDetailFeature />
    </PageBaseLayout>
  );
};
