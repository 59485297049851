import { useEffect } from 'react';

import { setBreadcrumbs } from '@app/stores';
import { PageBaseLayout, PersonalFeature } from '@app/components';
import { useAppDispatch, useAppSelector } from '@app/hooks';


export const PersonalPage = () => {
  const dispatch = useAppDispatch();
  const { breadcrumbs } = useAppSelector((s) => s.app);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Настройки', link: '' },
    ]))
  }, [dispatch]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
      col6
    >
      <PersonalFeature />
    </PageBaseLayout>
  );
};
