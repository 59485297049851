import {
  ButtonSaveBlock,
  ButtonBackBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FieldPasswordBlock,
  CardDataComposition,
  FormCenteredBlock,
} from '@app/components';

import {
  useMainApi,
} from '../../context';

import styles from './main.module.scss';

export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    userIsSubmitting,
    roleDictionary,
    departmentDictionary,
    changeFormValue,
    onSubmit,
    setError,
  } = useMainApi();

  return (
    <div className={styles['main']}>
      <CardDataComposition
        title="Добавить пользователя"
        extra={<ButtonBackBlock buttonIcon />}
        loading={userIsSubmitting}
      >
        <FormCenteredBlock
          onSubmit={onSubmit}
          buttons={
            <>
              <ButtonSaveBlock
                loading={userIsSubmitting}
                onClick={onSubmit}
                disabled={!formDirty}
              />
              <ButtonBackBlock />
            </>
          }
        >
          <FieldTextBlock
            name="lastName"
            label="Фамилия"
            value={formValue.lastName}
            onChange={changeFormValue}
            error={setError('lastName')}
            required
          />

          <FieldTextBlock
            name="firstName"
            label="Имя"
            value={formValue.firstName}
            onChange={changeFormValue}
            error={setError('firstName')}
            required
          />

          <FieldTextBlock
            name="secondName"
            label="Отчество"
            value={formValue.secondName}
            onChange={changeFormValue}
            error={setError('secondName')}
          />

          <FieldTextBlock
            name="email"
            label="Электронная почта"
            value={formValue.email}
            onChange={changeFormValue}
            error={setError('email')}
            required
          />

          <FieldPasswordBlock
            name="password"
            label="Пароль"
            value={formValue.password}
            onChange={changeFormValue}
            error={setError('password')}
            required
          />

          <FieldSelectBlock
            name="roleId"
            label="Роль"
            value={formValue.roleId}
            onChange={changeFormValue}
            error={setError('roleId')}
            required
            items={roleDictionary.map((item) => ({ value: item.id, label: item.name }))}
          />

          <FieldSelectBlock
            name="departmentId"
            label="Подразделение"
            value={formValue.departmentId}
            onChange={changeFormValue}
            error={setError('departmentId')}
            required
            items={departmentDictionary.map((item) => ({ value: item.id, label: item.name }))}
          />
        </FormCenteredBlock>
      </CardDataComposition>
    </div>
  );
};
